<template>
  <v-container fluid>
    <div class="d-flex">
      <v-select
        dense
        outlined
        style="max-width: 200px"
        v-model="selezionataConf"
        :items="configurazioni"
      ></v-select>
      <v-btn @click="caricaConfigurazione('pos')">POS</v-btn>
      <v-btn @click="caricaConfigurazione('acc')">ACC</v-btn>
    </div>
    <loader-modello
      :accessoriStruttura="struttura.accessori"
      :accessori="listaAccessoriCompleti"
      ref="loader"
    />
  </v-container>
</template>

<script>
import loaderModello from "@/components/loaderModello.vue";
export default {
  components: {
    loaderModello
  },
  data() {
    return {
      selezionataConf: null,
      accessori: [],
      conf: []
    };
  },
  methods: {
    caricaConfigurazione(a) {
      if (this.selezionataConf == null) {
        return;
      }
      // console.log(this.selezionataConf);
      const configurazione = this.$store.getters["categorie/configurazione"](
        this.selezionataConf
      );
      // console.log(configurazione);
      if (a == "pos") {
        // console.log("carica pos");
        this.$refs.loader.caricaConfigurazione(
          configurazione.configurazioneScenaPosizionamento
        );
      } else {
        // console.log("carica acc");
        this.$refs.loader.caricaConfigurazione(
          configurazione.configurazioneScenaAccessori
        );
      }
    }
  },
  computed: {
    configurazioni() {
      return this.$store.getters["categorie/listaConfigurazioni"];
    },
    listaAccessoriCompleti() {
      return this.$store.getters["categorie/listaAccessoriCompleti"];
    },
    struttura() {
      return this.$store.getters["struttura/struttura"];
    }
  }
};
</script>

<style></style>
